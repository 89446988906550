import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../src/Pages/Home/index.jsx'
import About from '../src/Pages/About/index.jsx'

function App() {
  return (
    <BrowserRouter>

      <Routes>
        {/* <Home/> */}
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
