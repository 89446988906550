import React from 'react'
import Navbar from '../../components/Navbar'
import Hero from '../../components/Hero'
import Features from '../../components/Features'
import Testimonial from '../../components/Testimonial'
import CallToAction from '../../components/CallToAction'
import Footer from '../../components/Footer'

const Home = () => {
  return (
    <div>
        <Navbar />
      <Hero />
      <Features />
      <Testimonial />
      <Footer />
    </div>
  )
}

export default Home