import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const About = () => {
  return (
    <div>
      <Navbar />
      <div className="w-full h-[80vh] flex flex-col items-center gap-10">
        <h1 className="text-3xl w-1/8 font-extrabold border-b-4 border-blue-600">
          ABOUT US
        </h1>
        <div className="flex flex-col justify-center items-center gap-10">
          <div className="text-center w-1/2">
            <span className="font-semibold">BANTU</span>{" "}
            <span className="text-blue-700 font-semibold">IT</span> is a leading
            software house dedicated to providing innovative solutions for
            businesses of all sizes. With a focus on desktop-based systems, web
            development, inventory management, and digital invitations, we
            strive to empower our clients with cutting-edge technology.
          </div>
          <div className="text-center w-1/2">
            Transform your ideas into fully functional and visually stunning
            websites. Our web development services cover a wide range of
            technologies to meet the unique needs of your business.
          </div>
          <div className="text-center w-1/2">
            Experience the power of innovation with our desktop-based system.
            This versatile and user-friendly solution is designed to streamline
            tasks, enhance security, and deliver a modern, efficient desktop
            experience. With cutting-edge features and a sleek interface, our
            system empowers you with next-generation capabilities for a seamless
            and optimized workflow.
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default About;
