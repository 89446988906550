import React from "react";

const Features = () => {
  return (
    <section id="features">
      {/* Flex Container */}
      <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row">
        {/* What's Different */}
        <div className="flex flex-col space-y-12 md:w-1/2">
          <h2 className="max-w-md text-4xl font-bold text-center md:text-left">
            Our <span className="border-b-4 border-blue-600">Services</span>
          </h2>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
            <span className="text-black font-extrabold">BANTU</span>{" "}
            <span className="text-blue-600 font-extrabold">IT</span> provides
            all the functionality your team needs, without the complexity. Our
            software is tailor-made for modern digital product teams.
          </p>
        </div>

        {/* Numbered List */}
        <div className="flex flex-col space-y-8 md:w-1/2">
          {/* List Item 1 */}
          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-blue-600">
                  01
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Web Development
                </h3>
              </div>
            </div>

            <div>
              <h3 className="hidden mb-4 text-lg font-bold md:block">
                Web Development
              </h3>
              <p className="text-darkGrayishBlue">
                Transform your ideas into fully functional and visually stunning
                websites. Our web development services cover a wide range of
                technologies to meet the unique needs of your business.
              </p>
            </div>
          </div>

          {/* List Item 2 */}
          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-blue-600">
                  02
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  UI/UX Design
                </h3>
              </div>
            </div>

            <div>
              <h3 className="hidden mb-4 text-lg font-bold md:block">
                UI/UX Design
              </h3>
              <p className="text-darkGrayishBlue">
                Create memorable user experiences with our expert UI/UX design
                team. We focus on aesthetics, usability, and functionality to
                elevate your brand.
              </p>
            </div>
          </div>

          {/* List Item 3 */}
          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-blue-600">
                  03
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Desktop Based System
                </h3>
              </div>
            </div>

            <div>
              <h3 className="hidden mb-4 text-lg font-bold md:block">
                Desktop Based System
              </h3>
              <p className="text-darkGrayishBlue">
                Experience the power of innovation with our desktop-based
                system. This versatile and user-friendly solution is designed to
                streamline tasks, enhance security, and deliver a modern,
                efficient desktop experience. With cutting-edge features and a
                sleek interface, our system empowers you with next-generation
                capabilities for a seamless and optimized workflow.
              </p>
            </div>
          </div>

          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-blue-600">
                  04
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Digital Invitation
                </h3>
              </div>
            </div>

            <div>
              <h3 className="hidden mb-4 text-lg font-bold md:block">
                Digital Invitation
              </h3>
              <p className="text-darkGrayishBlue">
                Discover a new way to invite guests with our digital invitation
                service. Effortlessly create personalized invites for any event,
                from weddings to birthdays. Our user-friendly platform makes it
                easy for everyone, while instant delivery ensures your invites
                reach guests on time. Embrace a modern and hassle-free approach
                to event planning!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
