import { Link } from "react-router-dom";

import features from "../assets/images/fast.png";
import team from "../assets/images/team.jpg";

const Testimonial = () => {
  return (
    <section id="testimonials" className="mb-10">
      {/* Container to heading and testm blocks */}
      <div className="max-w-6xl px-5 mx-auto mt-32 text-center">
        {/* Heading */}
        <h2 className="text-4xl font-bold text-center">
          What's Different About{" "}
          <span className="text-black font-extrabold">BANTU</span>{" "}
          <span className="text-blue-600 font-extrabold">IT</span>?
        </h2>
        {/* Testimonials Container */}
        <div className="flex flex-col mt-24 md:flex-row justify-center md:space-x-6">
          {/* Testimonial 1 */}
          <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3">
            <img src={features} className="w-16 -mt-14" alt="" />
            <h5 className="text-lg font-bold">Fast Work</h5>
            <p className="text-sm text-darkGrayishBlue">
              “Get things done faster with our quick and efficient work
              solutions. Experience speedy workflows that save you time and
              effort. Our technology ensures high performance, helping you
              accomplish tasks with ease. Stay ahead with a work environment
              that values speed, simplicity, and efficiency”
            </p>
          </div>

          {/* Testimonial 2 */}
          <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
            <img src={team} className="w-28 -mt-14 rounded-full" alt="" />
            <h5 className="text-lg font-bold">Professional Team</h5>
            <p className="text-sm text-darkGrayishBlue">
              “Say hello to our super skilled team! We're experts at what we do,
              always giving our best to make sure you get top-notch service.
              Count on us for reliable and friendly help—your success is what
              drives us!”
            </p>
          </div>
        </div>
        {/* Button */}
        
      </div>
    </section>
  );
};

export default Testimonial;
