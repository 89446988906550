import { Link } from "react-router-dom";

import facebookLogo from "../assets/images/icon-facebook.svg";
import youtubeLogo from "../assets/images/icon-youtube.svg";
import pinterestLogo from "../assets/images/icon-pinterest.svg";
import instagramLogo from "../assets/images/icon-instagram.svg";

const Footer = () => {
  return (
    <div className="bg-veryDarkBlue">
      {/* Flex Container */}
      <div className="container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0">
        {/* Logo and social links container */}
        <div className="flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start">
          <div className="mx-auto my-6 text-center text-white md:hidden">
            Copyright © 2022, All Rights Reserved
          </div>
          {/* Logo */}
          <div>
            {/* <img src={companyLogoWhite} className='h-8' alt='' /> */}
          </div>
          {/* Social Links Container */}
          <div className="flex justify-center space-x-4">
            {/* Link 1 */}
            {/* <Link to="#">
              <img src={facebookLogo} className="h-8" alt="" />
            </Link> */}
            {/* Link 2 */}
            <Link to="#">
              <img src={youtubeLogo} className="h-8" alt="" />
            </Link>
            {/* Link 3 */}
            {/* Link 4 */}
            {/* <Link to="#">
              <img src={pinterestLogo} className="h-8" alt="" />
            </Link> */}
            {/* Link 5 */}
            <Link to="#">
              <img src={instagramLogo} className="h-8" alt="" />
            </Link>
          </div>
        </div>
        {/* List Container */}
        <div className="flex justify-around space-x-32">
          <div className="flex flex-col space-y-3 text-white">
            <Link to="/" className="hover:text-brightRed">
              Home
            </Link>
            <Link to="/about" className="hover:text-brightRed">
              About US
            </Link>
            <Link to="" className="hover:text-brightRed">
              Contact
            </Link>
          </div>
        </div>

        {/* Input Container */}
        <div className="flex flex-col justify-between">
          <div className=" text-white md:block hidden pt-20 ">
            Copyright © 2022, All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
